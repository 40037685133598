import styled from "@emotion/styled";

export const CourseSpeakers = styled("div")`
    label:CourseSpeakers;
    width:80%;
    margin:auto;
    flex-direction: column;

    @media (max-width: 768px) {


       }
    

    `;

