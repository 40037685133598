const theme = {
    primary: "#58a58f;",
    secondary: "rgba(48, 146, 85, 0.2)",
    third: "#212832;",
    balck: "0000",
    gray: "#707070",
    white: "#ffff",
    active: "#f2545f",
    padding: "#555"

};
export default theme;