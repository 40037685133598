import React from 'react';
import Footer from '../../desing-system/components/Footer/Footer';
import CoursesTop from './components/CoursesTop/CoursesTop';


export default function Quizzes() {
  return (
    <div className='Container'><CoursesTop />
      <Footer />

    </div>
  )
}
