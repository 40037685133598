
import styled from "@emotion/styled";

export const ContainerHOME = styled("div")`
   label:ContainerHOME;
   max-width: 1200px;
   margin: 0 auto;
   padding:0px;
   margin-top:80px;


`;

